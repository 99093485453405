import React from 'react';
import WaistManual from "../../components/WaistManual";
import useWaistJPImages from "../../hooks/useWaistJPImages";

const locale = "jp";
export default () => {
	const images = useWaistJPImages();
	return (
		<>
			<WaistManual locale={locale} images={images}/>
		</>
	);
}
